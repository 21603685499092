<template>
  <b-modal
    id="confirm-application-rejection-modal"
    ref="confirm-application-rejection-modal"
    centered
    size="lg"
    :title="$t('Confirm rejection')"
    title-class="font-weight-bolder"
    :ok-title="$t('Confirm rejection')"
    @ok.prevent="confirmRejection"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-primary"
    @hidden="onHidden"
  >
    <div class="mb-2">
      <b-card-text class="font-weight-bolder">
        {{ $t('Are you sure that you want to reject this application?') }}
      </b-card-text>
      <validation-observer ref="send-message-and-reject-modal-observer">
        <validation-provider
          v-slot="{ errors }"
          name="message"
          rules="min:3|max:1400"
          mode="lazy"
        >
          <!-- <quill-editor
            v-model="message"
            :options="editorOptions"
          /> -->
          <b-form-textarea
            rows="5"
            no-resize
            v-model="message"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BFormTextarea,
  BModal
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { required, min, max } from '@mentoring-platform-validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCardText,
    BFormTextarea,
    BModal,
    quillEditor,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      message: '',
      editorOptions: { // https://vueup.github.io/vue-quill/guide/options.html#option-attributes
        placeholder: this.$t('Type your message...'),
        theme: 'snow'
      }
    }
  },
  methods: {
    show() {
      this.$refs['confirm-application-rejection-modal'].show()
    },
    onHidden() {
      this.message = ''
    },
    confirmRejection() {
      this.$refs['send-message-and-reject-modal-observer'].validate().then(async success => {
        if(success) {
          this.$emit('application-rejection-confirmed', this.message)
          this.$refs['confirm-application-rejection-modal'].hide()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
