import { render, staticRenderFns } from "./PendingApplication.vue?vue&type=template&id=bb9d184e&"
import script from "./PendingApplication.vue?vue&type=script&lang=js&"
export * from "./PendingApplication.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports