<template>
  <div>
    <page-header-admin class="mb-2" :title="$t('Application')" />
    <b-row>
      <b-col>
        <b-card v-if="mentorApplication">
          <b-row>
            <b-col md="2" class="mb-1">
              <div class="d-flex justify-content-start">
                <b-avatar
                  class="grayscale-avatar"
                  :src="mentorApplication.user.photo_full_path"
                  :text="mentorFullname"
                  size="104px"
                />
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column">
                <h4 class="mb-0">
                  {{ mentorFullname }}
                </h4>
                <div class="mb-2">
                  <span class="card-text">{{ $t('Application date') }}</span>:
                  <span class="card-text">{{ mentorApplication.created_at | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit'  }) }}</span>
                </div>
                <div>
                  <b-button variant="primary" class="mr-1 mb-1" @click="approveApplication">
                    {{ $t('Approve application') }}
                  </b-button>
                  <b-button variant="outline-primary" class="mr-1 mb-1" @click="sendMessage">
                    {{ $t('Send message') }}
                  </b-button>
                  <b-button variant="danger" class="mb-1" @click="rejectApplication">
                    {{ $t('Reject application') }}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <mentor-messages v-if="mentorApplication" :mentor="mentorApplication" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col md="6">
              <b-card-title>
                {{ $t('Settings') }}
              </b-card-title>
              <mentor-settings ref="mentor-settings" v-if="mentorApplication" :showCTA="false" :mentor="mentorApplication" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <mentoring-languages ref="mentoring-languages" v-if="mentorApplication"  :mentor="mentorApplication" emit-event />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <personal-details ref="personal-details" v-if="mentorApplication" :mentor="mentorApplication" :email-pre-check="false" showMentorFields />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <professional-details ref="professional-details" v-if="mentorApplication" :mentor="mentorApplication" showMentorFields />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <mentor-role ref="mentor-role" v-if="mentorApplication" :mentor="mentorApplication" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <mentor-profile ref="mentor-profile" v-if="mentorApplication" :mentor="mentorApplication" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="primary" class="mr-1 mb-1" @click="approveApplication">
          {{ $t('Approve application') }}
        </b-button>
        <b-button variant="outline-primary" class="mr-1 mb-1" @click="sendMessage">
          {{ $t('Send message') }}
        </b-button>
        <b-button variant="danger" class="mb-1" @click="rejectApplication">
          {{ $t('Reject application') }}
        </b-button>
      </b-col>
    </b-row>
    <confirm-application-rejection-modal ref="confirm-application-rejection-modal" @application-rejection-confirmed="rejectionConfirmed" />
    <send-message-modal v-if="mentorApplication" ref="send-message-modal" :mentor="mentorApplication" />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardTitle,
  BCol,
  BRow
} from 'bootstrap-vue'
import PersonalDetails from '@mentoring-platform/views/components/blocks/PersonalDetails.vue'
import ProfessionalDetails from '@mentoring-platform/views/components/blocks/ProfessionalDetails.vue'
import MentorRole from '@mentoring-platform/views/components/blocks/MentorRole.vue'
import MentorProfile from '@mentoring-platform/views/components/blocks/MentorProfile.vue'
import MentorSettings from '@mentoring-platform/views/components/blocks/MentorSettings.vue'
import MentorMessages from '@mentoring-platform/views/components/blocks/MentorMessages.vue'
import { useMentorRepository, useMentorHelpers } from '@mentoring-platform/composables'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'
import { mentorStatuses } from '@mentoring-platform/constants'
import ConfirmApplicationRejectionModal from '@mentoring-platform/views/components/blocks/MentorApplication/ConfirmApplicationRejectionModal.vue'
import SendMessageModal from '@mentoring-platform/views/components/blocks/MentorApplication/SendMessageModal.vue'
import { formatGreekDate } from '@mentoring-platform/utils/filters'
import MentoringLanguages from '@mentoring-platform/views/components/blocks/MentoringLanguages.vue'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BCardTitle,
    BCol,
    BRow,
    ConfirmApplicationRejectionModal,
    PersonalDetails,
    ProfessionalDetails,
    MentoringLanguages,
    MentorRole,
    MentorProfile,
    MentorSettings,
    MentorMessages,
    PageHeaderAdmin,
    SendMessageModal
  },
  filters: {
    formatGreekDate
  },
  data() {
    return {
      mentorApplication: null
    }
  },
  computed: {
    mentorFullname() {
      return this.getMentorVisibleFullName(this.mentorApplication)
    }
  },
  async created() {
    this.mentorApplication = await this.getSpecificMentorApplication(this.$route.params.id)
  },
  methods: {
    isValid() {
      return Promise.all([
        this.$refs['mentoring-languages'].isValid(),
        this.$refs['mentor-settings'].isValid(),
        this.$refs['personal-details'].isValid(),
        this.$refs['professional-details'].isValid(),
        this.$refs['mentor-role'].isValid(),
        this.$refs['mentor-profile'].isValid(),
      ])
        .then((values) => {
          return !values.includes(false)
        })
    },
    collectData() {
      return {
        ...this.$refs['mentoring-languages'].collectData(),
        ...this.$refs['mentor-settings'].collectData(),
        ...this.$refs['personal-details'].collectData(),
        ...this.$refs['professional-details'].collectData(),
        ...this.$refs['mentor-role'].collectData(),
        ...this.$refs['mentor-profile'].collectData()
      }
    },
    async approveApplication() {
      const isValid = await this.isValid()
      if(isValid) {
        const payload = this.collectData()
        payload.application_id = this.mentorApplication.id
        this.$store.dispatch('mentor/approveApplication', payload)
          .then(response => {
            this.$root.$bvToast.toast(this.$t('Pending mentor application approved'), {
              title: this.$t('Confirmation'),
              variant: 'success',
              solid: true,
            })
            this.refreshMentorApplications()
            this.refreshMentors()
            this.$router.push({name: 'admin-pending-applications'})
          })
          .catch(error => {
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          })
      }
    },
    async rejectApplication() {
      if(!this.$refs['mentor-settings'].selectedStatus) {
        await this.$set(this.$refs['mentor-settings'], 'selectedStatus', mentorStatuses.MENTOR_STATUS_INACTIVE) // just select a status to pass validation
      }
      const isValid = await this.isValid()
      if(isValid) {
        this.$refs['confirm-application-rejection-modal'].show()
      }
    },
    rejectionConfirmed(message) {
      const payload = this.collectData()
      if(message) {
        payload.message = message
      }
      payload.application_id = this.mentorApplication.id
      payload.status = mentorStatuses.MENTOR_STATUS_REJECTED
      this.$store.dispatch('mentor/approveApplication', payload)
        .then(response => {
          this.$root.$bvToast.toast(this.$t('Pending mentor application rejected'), {
            title: this.$t('Confirmation'),
            variant: 'success',
            solid: true,
          })
          this.refreshMentorApplications()
          this.refreshMentors()
          this.$router.push({name: 'admin-pending-applications'})
        })
        .catch(error => {
          this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        })
    },
    sendMessage() {
      this.$refs['send-message-modal'].show()
    }
  },
  setup() {
    const {
      getMentorVisibleFullName
    } = useMentorHelpers()
    const {
      getSpecificMentorApplication,
      refreshMentorApplications,
      refreshMentors
    } = useMentorRepository()

    return {
      getSpecificMentorApplication,
      getMentorVisibleFullName,
      refreshMentorApplications,
      refreshMentors
    }
  },
}
</script>
